import { createContext } from "react";

const PlayerContext = createContext ({
    currentItem: {
        actions: [],
        author: "",
        image: "",
        link: "",
        title: ""
    },
    duration: 0,
    loading: true,
    loopPlaylist: false,
    loopTrack: false,
    playing: false,
    progress: {
        loaded: 0,
        loadedSeconds: 0,
        played: 0,
        playedSeconds: 0
    },
    setCurrentItem: () => {},
    setCurrentItemID: () => {},
    next: () => {},
    prev: () => {},
    seekTo: () => {},
    setClosePlayer: () => {},
    setDuration: () => {},
    setLoopPlaylist: () => {},
    setLoopTrack: () => {},
    setProgress: () => {},
    setShowPlaylist: () => {},
    setVolume: () => {},
    showPlayer: true,
    showPlaylist: true,
    status: false,
    togglePlaying: () => {},
    volume: 0.5,
})

export default PlayerContext;


// value
// :
// {currentItem: {…}, currentItemDescriptionId: null, …}

// currentItem
// :
// {actions: Array(0), author: "Mikhail Kotov", image:…}
// actions
// :
// []
// author
// :
// "Mikhail Kotov"
// image
// :
// "https://orchestrascoring.com/wp-content/uploads/2021/12/Warpath-Soundtrack.jpg"
// link
// :
// "https://files.elfsight.com/storage/68424e7d-6e62-4ac8-998e-511268b0a03c/452d4d9b-ef82-46f9-a712-923de1f0d620.m4a"
// title
// :
// "Warpath - Tested By Fire"
// currentItemDescriptionId
// :
// null
// currentItemId
// :
// 2

// items
// :
// [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]

// 0
// :
// {actions: Array(0), author: "Mikhail Kotov", image:…}
// actions
// :
// []
// author
// :
// "Mikhail Kotov"
// image
// :
// "https://orchestrascoring.com/wp-content/uploads/2021/12/Warpath-Soundtrack.jpg"
// link
// :
// "https://files.elfsight.com/storage/68424e7d-6e62-4ac8-998e-511268b0a03c/54f930c1-b693-4b5b-a3fa-085cfe58e67c.m4a"
// title
// :
// "Warpath - City Siege"

// 1
// :
// {actions: Array(0), author: "Mikhail Kotov", image:…}

// 2
// :
// {actions: Array(0), author: "Mikhail Kotov", image:…}

// 3
// :
// {actions: Array(0), author: "Mikhail Kotov", image:…}

// 4
// :
// {actions: Array(0), author: "Alexei Aigui", image: …}

// 5
// :
// {actions: Array(0), author: "Alexei Aigui", image: …}

// 6
// :
// {actions: Array(0), author: "Alexei Aigui", image: …}

// 7
// :
// {actions: Array(0), author: "Alexei Aigui", image: …}
// setCurrentItem
// :
// ƒ bound go() {}
// setCurrentItemDescriptionId
// :
// ƒ bound go() {}
// setCurrentItemId
// :
// ƒ bound go() {}
// setItems
// :
// ƒ bound go() {}
// setShuffle
// :
// ƒ bound go() {}
// shuffle
// :
// false