import ProgressBar from '../ProgressBar/ProgressBar';
import PlayerControls from '../PlayerControls/PlayerControls';

import styles from "./PlayerActions.module.css";

const PlayerActions = (props) => {

    return (
        <div className={styles.playerActions}>
            <ProgressBar/>
            <PlayerControls/>
        </div>
    );
}

export default PlayerActions;