const displayTime = (duration) => {
    duration = Math.trunc(duration);
    const minutes = Math.trunc(duration/60)
    const seconds = duration % 60
    const dMinutes = minutes > 9 ? `${minutes}` : `0${minutes}`;
    const dSeconds = seconds > 9 ? `${seconds}` : `0${seconds}`;
    return `${dMinutes}:${dSeconds}`
}

const percentToTime = (percent, duration) => {
    return displayTime(Math.trunc(duration*percent/100));
}

export { displayTime, percentToTime };