import { useContext } from 'react'
import PlayerActions from '../PlayerActions/PlayerActions';
import PlayerContext from '../Context/PlayerContext';

import './player.css';

const Player = () => {

    const context = useContext(PlayerContext)

    const {author, image, title} = context.currentItem;
    // const {duration, played, loaded} = props;
    // const {onDrag} = props;

    return(
        <div className="player-container">
            <div className="album-art"><img src={image} alt="" /></div>
            <div className="player-full">
                <div className="player-header">
                    <div className="player-trackname">{title}</div>
                    <div className="player-artistname">{author}</div>
                </div>
                <PlayerActions/>
            </div>
        </div>
    );
}

export default Player;