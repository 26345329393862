import { useState, useContext, useEffect, useRef } from 'react';
import { displayTime, percentToTime } from '../../utils/utils';
import { PlayerLogicContext } from '../App';
import styles from "./ProgressBar.module.css"

const ProgressBar = () => {

    const {currentItemID, duration, dragging, buffering, seeking, setDragging, progress: {played, playedSeconds, loaded}, status} = useContext(PlayerLogicContext)
    const [hoverPosition, setHoverPosition] = useState(0);
    const [dragPosition, setDragPosition] = useState(0);
    
    // Define Progress Bar Position
    const progressBar = useRef(null);
    const getProgressBarLeft = () => progressBar.current.getBoundingClientRect().left;
    const getProgressBarRight = () => progressBar.current.getBoundingClientRect().right;

    //Dragging Handler
    const setDragPositionLR = (e) => {
        const type = e.type;
        const clientX = type.indexOf('mouse') !== -1 ? e.clientX : e.touches[0].clientX
        const coordX = clientX - getProgressBarLeft();
        const width = getProgressBarRight() - getProgressBarLeft();
        let position;

        if (coordX/width > 1) {
            position = 100;
        } else if (coordX/width < 0) {
            position = 0;
        } else {
            position = 100*coordX/width;
        }
        setDragPosition(position)
        if (type.indexOf('touch') >= 0) {
            setHoverPosition(position)
        }
}

    useEffect(() => {
        setDragPosition(0);
    }, [currentItemID])

	const dragStop = (e) => {
		window.removeEventListener('mousemove', setDragPositionLR);
        window.removeEventListener('touchmove', setDragPositionLR);
		setDragging(false);
	}

	useEffect(() => {
		if (dragging) {
			window.addEventListener('mousemove', setDragPositionLR);
			window.addEventListener('touchmove', setDragPositionLR);
			window.addEventListener('mouseup', dragStop);
			window.addEventListener('touchend', dragStop);
		} else {
            status.current.seekOnPlay = dragPosition*duration/100;
        }
		return (() => {
			window.removeEventListener('mouseup', dragStop);
            window.removeEventListener('touchend', dragStop);
		})
	}, [dragging])

    const hoverMove = (e) => {
        const {left, right} = e.currentTarget.getBoundingClientRect(),
        width = right - left,
        coordX = e.clientX - left,
        position = (coordX / width)*100;
        setHoverPosition(position);
    }
        
        return (
            <div 
                className={styles.component}
                onMouseMove={hoverMove}
                onMouseDown={(e) => {setDragging(true); setDragPositionLR(e)}}
                onTouchStart={(e) => {setDragging(true); setDragPositionLR(e)}}
                >
                <div className={styles.track}>
                    <div className={styles.time}>
                        <div className={styles.currentTime}>{displayTime(playedSeconds)}</div>
                        <div className={styles.duration}>{displayTime(duration)}</div>
                    </div>
                    <div ref={progressBar} className={styles.fakeTrack}></div>
                    <div className={styles.loaded} style={{width: `${loaded*100}%`}}></div>
                    <div className={styles.played} style={dragging || buffering || seeking ?  {width: `${dragPosition}%`, transition: '0s all', opacity: '1'} : {width: `${played*100}%`}}></div>
                    <div className={styles.thumb} draggable="false" style={dragging ? {left: `${dragPosition}%`, transition: '0s all', opacity: '1'} : buffering || seeking ? {left: `${dragPosition}%`} : {left: `${played*100}%`}}></div>
                    <div className={styles.hoverTime} style={{left: `${hoverPosition}%`}}>{percentToTime(hoverPosition, duration)}</div>
                </div>
            </div>
        )
}

export default ProgressBar;