import { useContext } from 'react'

import PlaylistItem from '../PlaylistItem/PlaylistItem'
import styles from './Playlist.module.css'
import PlayerContext from '../Context/PlayerContext'
import { PlayerLogicContext } from '../App'

const Playlist = () => {

	const {items} = useContext(PlayerContext);
	const {injectTrack, currentItemID, play, togglePlay, togglePause} = useContext(PlayerLogicContext);

	  let playlistItems = items.map((item, index) => {
		  return (
			  <PlaylistItem 
			  	key={index} 
				injected={index===currentItemID} 
				playing={index===currentItemID && play} 
				playlistItemInfo={item} 
				onTrackSelected={() => {
					injectTrack(index, false);
					if (index === currentItemID && play) {
						togglePause();
					} else {
						togglePlay();
					}
				}
				}/>
		  )
	  })

    return (
        	<div className={styles.frame}>
            	<div className={styles.container}>
					{playlistItems}
            	</div>
				{/* <div className={styles.fakeScrollBar}>
					<div className={styles.fakeScrollBar_thumb}></div>
				</div> */}
        	</div>
    )
}

export default Playlist;