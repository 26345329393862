import { useState, useRef, createContext, useReducer, useEffect, useContext, useCallback} from 'react';

import Player from './Player/Player';
import Playlist from "./Playlist/Playlist";
import PlayerContext from "./Context/PlayerContext";

function reducer(state, action) {
	switch(action.type) {
		case 'load start':
			return {...state, loading: true}
		case 'ready':
			return {...state, loading: false}
		case 'metadata':
			return {...state, duration: action.payload}
		case 'buffering':
			return {...state, buffering: action.payload}
		case 'dragging':
			return {...state, dragging: action.payload}
		case 'seeking':
			return {...state, seeking: action.payload}
		case 'playing':
			return {...state, playing: true}
		case 'onpause':
			return {...state, playing: false}
		case 'play':
			return {...state, play: true}
		case 'pause':
			return {...state, play: false}
		case 'ended':
			return {...state, ended: true}
		case 'reload':
			return {...state, progress: {played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0}}
		case 'playingProgress':
			return {...state, progress: {...state.progress, playedSeconds: action.payload, played: action.payload/state.duration}}
		case 'loadingProgress':
			return {...state, progress: {...state.progress, loadedSeconds: action.loadedSeconds, loaded: action.loaded}}
	}
}

const PlayerLogicContext = createContext({});
export { PlayerLogicContext };

const PlayerLogic = () => {
	const {items, currentItem, currentItemID, setCurrentItem, setCurrentItemID} = useContext(PlayerContext);
	const maxId = items.length-1;
	const audioPlayer = useRef(null);

	const [state, dispatch] = useReducer(reducer, {
			loading: true,
			buffering: false,
			play: false,
			playing: false,
			ended: false,
			duration: 0,
			dragging: false,
			seeking: false,
			progress: {
				played: 0,
				playedSeconds: 0,
				loaded: 0,
				loadedSeconds: 0
			}
		}
	)

	const status = useRef({
		isReady: false,
		isBuffering: false,
  		isPlaying: false,
  		isLoading: true,
		seekOnPlay: null,
  		loadOnReady: null
	});

	const [volume, setVolume] = useState(0.5)

	const setDragging = (value) => {
		dispatch({type: 'dragging', payload: value});
		if (value) {
			dispatch({type:'seeking', payload: true})
		}
	}

	const togglePlay = () => {dispatch({type: 'play'})}

	const togglePause = () => {dispatch({type: 'pause'})}

	const togglePlayPause = () => {
		if (state.play) {
			togglePause()
		} else {
			togglePlay()
		}
	}

	const goToTime = (time) => {
		audioPlayer.current.currentTime = time; 
		// console.log(`go to time ${time} audioPlayer current time ${audioPlayer.current.currentTime}`)
	}

	const injectTrack = (id) => {
		setCurrentItem(items[id])
		setCurrentItemID(id)
	}

	const next = useCallback(() => {
		if (currentItemID<maxId) {
			injectTrack(currentItemID+1)
		} else {
			injectTrack(0)
		}
	}, [currentItemID])

	const prev = useCallback(() => {
		if (currentItemID>0) {
			injectTrack(currentItemID-1)
			// console.log(`trying to play ${currentItemID-1}`)
		} else {
			injectTrack(maxId)
		}
	}, [currentItemID])

	//Handlers for Audio Player Status

	const handleReady = () => {
		status.current.isReady = true
		status.current.isLoading = false
		dispatch({type:'ready'});
		if (status.current.loadOnReady) {
			audioPlayer.current.load(status.current.loadOnReady)
			status.current.loadOnReady = null
		} else if (state.play) {
			// console.log('handle ready - play');
			audioPlayer.current.play()
		}
	}

	const handlePlay = () => {
		status.current.isPlaying = true;
		status.current.isLoading = false;
		dispatch({type: 'playing'})
		// console.log('handle play');
		console.log(audioPlayer.current.currentTime);
	}

	const handlePause = () => {
		status.current.isPlaying = false;
		dispatch({type: 'onpause'})
		// console.log('handle pause');
	}

	const handleLoaded = () => {
		status.current.isLoading = false
	}

	const handleBuffering = () => {
		console.log('paused for buffering');
		status.current.isBuffering = true
		dispatch({type: 'buffering', payload: true})
	}
	const handleBufferingEnd = () => {
		console.log('finished buffering');
		status.current.isBuffering = false;
		dispatch({type: 'buffering', payload: false})
	}

	const handleReload = () => {
		status.current.isPlaying = false;
		status.current.isLoading = true;
		dispatch({type: 'load start'})
		dispatch({type: 'reload'})
		// console.log('handle reload');
	}

	const handleSeeked = () => {
		// console.log('seeked');
		dispatch({type:'seeking', payload: false})
	}

	// Invoke Effects depending on State Changes


	// When changing track
	useEffect(() => {
		handleReload();
	}, [currentItemID])

	// When user toggles Play/Pause or change track
	useEffect(() => {
		if (state.play && !status.current.isPlaying) {
			audioPlayer.current.play();
			if (status.current.seekOnPlay) {
				goToTime(status.current.seekOnPlay);
				status.current.seekOnPlay = null;
			}
			// console.log('playing');
		}
		if (!state.play && status.current.isPlaying) {
			// console.log('pausing');
			audioPlayer.current.pause();
		}
	}, [currentItemID, state.play])


	//When finished dragging Progress Bar
	useEffect(() => {
		if (!state.dragging && state.play && status.current.isPlaying) {
			audioPlayer.current.play();
			if (status.current.seekOnPlay) {
				goToTime(status.current.seekOnPlay);
				status.current.seekOnPlay = null
			}
		}
	}, [state.dragging])


	useEffect(() => {
		audioPlayer.current.onended = function() {
			// console.log('ended');
			dispatch({type:'ended'})
			next();
		}
	}, [next])

	useEffect(() => {
		audioPlayer.current.volume = volume
	}, [volume])


	// Initialization
	useEffect(() => {
		const a = audioPlayer.current;
		a.volume = volume;
		injectTrack(0);

		a.oncanplay = () => {handleReady()}
		a.onplay = () => {handlePlay()}
		a.onplaying = () => {handleBufferingEnd()}
		a.onpause = () => {handlePause()}
		a.onwaiting = () => {handleBuffering()}
		a.onseeked = () => {handleSeeked()}
		a.onloadstart = () => {dispatch({type: 'load start'})}
		a.onloadedmetadata = () => {dispatch({type: 'metadata', payload: a.duration})}
		a.ontimeupdate = () => {dispatch({type: 'playingProgress', payload: a.currentTime})}
		// a.onloadeddata = () => {console.log('loaded data')}
		a.onprogress = () => {
					if (a.duration>0 && a.buffered.length) {
						const bufferedEnd = a.buffered.end(a.buffered.length-1);
						// console.log('buffered update');
						dispatch({type: 'loadingProgress', loadedSeconds: bufferedEnd, loaded: bufferedEnd/a.duration});
					}
		}
	},[]);

	return (
		<>
			<audio ref={audioPlayer} hidden preload="auto" controls src={currentItem.link}></audio>
			<PlayerLogicContext.Provider value={{...state, 
					currentItem,
					currentItemID,
					status,
					volume,
					setVolume,
					togglePlayPause,
					togglePlay,
					togglePause,
					next,
					prev,
					injectTrack,
					setDragging,
					goToTime
					}}>
				<Player/>
				<Playlist/>
			</PlayerLogicContext.Provider>
		</>
	)
}


const PlayerSettings = (props) => {
	const [currentItem, setCurrentItem] = useState({})
	const [items, setItems] = useState(props.data.source);
	const [currentItemID, setCurrentItemID] = useState(0);
	

	const value = {
		currentItem: {...currentItem},
		items,
		currentItemID,
		setCurrentItem,
		setCurrentItemID,
		setItems
	}

	return (
		<PlayerContext.Provider value={value}>
			<PlayerLogic/>
		</PlayerContext.Provider>
	)
}

const App = () => {

	const [data, setData] = useState(
		{
			source: [
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/Warpath-Soundtrack.jpg', author: 'Mikhail Kotov', title: 'Warpath - City Siege', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/07-Warpath-City-Siege.m4a"},
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/Warpath-Soundtrack.jpg', author: 'Mikhail Kotov', title: 'Warpath - Desert Crossfire', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/02-Warpath-Desert-Crossfire.m4a"},
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/Warpath-Soundtrack.jpg', author: 'Mikhail Kotov', title: 'Warpath - Tested By Fire', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/03-Warpath-Tested-By-Fire.m4a"},
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/Warpath-Soundtrack.jpg', author: 'Mikhail Kotov', title: 'Warpath - Operation Bagration', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/08-Warpath-Operation-Bagration.m4a"},
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/White-Snow-Soundtrack.jpg', author: 'Alexei Aigui', title: 'White Snow - Refusal', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/04-White-Snow-Refusal.m4a"},
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/White-Snow-Soundtrack.jpg', author: 'Alexei Aigui', title: 'White Snow - Going Home', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/10-White-Snow-Going-Home.m4a"},
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/White-Snow-Soundtrack.jpg', author: 'Alexei Aigui', title: 'White Snow - Losing', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/03-White-Snow-Losing.m4a"},
				{image: 'https://orchestrascoring.com/wp-content/uploads/2021/12/White-Snow-Soundtrack.jpg', author: 'Alexei Aigui', title: 'White Snow - First Kiss', link: "https://orchestrascoring.com/wp-content/uploads/2022/05/08-White-Snow-First-Kiss.m4a"},
			]
		}
	)
	

	return (
		<div className="container">
				<PlayerSettings data={data}/>
		</div>
	)
}

export {App};